import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.svg'; // Importa la imagen del logo

const Navbar = () => {
  return (
    <nav className="bg-[#333333] p-4" style={{ fontFamily: 'Source Sans Pro, sans-serif' }}>
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-white text-lg font-bold">
          <Link to="https://imco.org.mx/">
            <img src={logo} alt="IMCO" className="h-12" /> {/* Utiliza la imagen del logo */}
          </Link>
        </div>
        <div className="flex space-x-4">
          <Link to="/menu" className="text-gray-300 hover:text-white">Inicio</Link>
          <Link to="https://imco.org.mx/indices/" className="text-gray-300 hover:text-white">Ediciones anteriores</Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
