import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import LandingPage from './components/LandingPage';
import UserDashboard from './components/UserDashboard';
import UserDashboard_2 from './components/UserDashboard_2';
import UserMenu from './components/UserMenu';
import SupportContactPage from './components/SupportContactPage';
import Navbar from './components/Navbar'; // Importa el componente Navbar
import Footer from './components/Footer'; // Importa el componente Footer
///deploy comment
const RoleBasedRedirect = () => {
  const { getIdTokenClaims, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const [userRoles, setUserRoles] = React.useState([]);

  React.useEffect(() => {
    const getUserRoles = async () => {
      if (isAuthenticated) {
        try {
          const idTokenClaims = await getIdTokenClaims();
          const roles = idTokenClaims['http://myapp.com/roles'];
          if (roles) {
            setUserRoles(roles);
          }
          // Redirect based on role
          if (roles && roles.includes('AMBOS')) {
            navigate('/menu');
          } else if (roles && roles.includes('ICE')) {
            navigate('/ice');
          } else if (roles && roles.includes('ICU')) {
            navigate('/icu');
          } else {
            navigate('/support-contact');
          }
        } catch (e) {
          console.error('Error obteniendo los claims del ID token:', e);
        }
      }
    };

    getUserRoles();
  }, [getIdTokenClaims, isAuthenticated, navigate]);

  return null; // Este componente no renderiza nada
};

const App = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar /> {/* Agrega la barra de navegación aquí */}
        <RoleBasedRedirect /> {/* Keep this component inside the Router but outside Routes */}
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/ice" element={<UserDashboard />} />
            <Route path="/icu" element={<UserDashboard_2 />} />
            <Route path="/menu" element={<UserMenu />} />
            <Route path="/support-contact" element={<SupportContactPage />} />
            {/* Add more routes as needed */}
          </Routes>
        </div>
        <Footer /> {/* Agrega el pie de página aquí */}
      </div>
    </Router>
  );
};

export default App;
